<template>
  <footer>
    \ <strong>Address</strong>: #05-03, 196 Pandan Loop, Singapore 128384 \ \
    <strong>Phone</strong>: +65 6779 2226 \ \ <strong>Email</strong>:
    hello@lambda.sg \
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  background: var(--color-white);
  padding: var(--sp-16);
  font-size: var(--font-size-s);
  text-align: center;
}
</style>
